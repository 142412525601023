import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailSubscriptionComponent } from './email-subscription/email-subscription.component';
import { VerifyEmailResponseComponent } from './verify-email-response/verify-email-response.component';

export const routes: Routes = [
  { path: 'subscription', component: EmailSubscriptionComponent },
  { path: 'verify-email-response', component: VerifyEmailResponseComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
