import { Component, OnInit } from '@angular/core';
declare const loader: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  ngOnInit() {
    loader.ajaxindicatorstop();
  }
}
