import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { MainComponent } from './main';
import { AppRoutingModule } from './app.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { EmailSubscriptionComponent } from './email-subscription/email-subscription.component';
import { VerifyEmailResponseComponent } from './verify-email-response/verify-email-response.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    EmailSubscriptionComponent,
    VerifyEmailResponseComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  providers: [
    ApiService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  exports: [],
  bootstrap: [MainComponent]
})
export class AppModule { }
