<div class="root container-alt">
  <div class="content">
    <div class="wrapper">
      <div class="logo">
        <img src="/assets/images/icons-trudi.svg" alt="">
      </div>
      <div class="thank-text">
        Thanks, your correspondance email address for <span class="prop-address">{{propertyAddress}}</span> has been successfully updated.
      </div>
    </div>
  </div>
  <div class="content-img">
    <img src="/assets/images/email.svg" alt="image">
  </div>
</div>
