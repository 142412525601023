import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare const loader: any;

@Component({
  selector: 'app-verify-email-response',
  templateUrl: './verify-email-response.component.html',
  styleUrls: ['./verify-email-response.component.scss']
})
export class VerifyEmailResponseComponent implements OnInit {
  public propertyAddress = '';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    loader.ajaxindicatorstop();
    this.route.queryParams.subscribe(params => {
      if (params) {
        this.propertyAddress = decodeURIComponent(decodeURIComponent(params['address']));
      }
    });
  }
}
