export const environment = {
  production: false,
};
export const s3 = {
  accessKeyId: 'AKIAZ3KE2D5NZHRWEPHA',
  secretAccessKey: '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',
  bucketName: 'stage-users-trulet-bucket'
};
export const apiKey = 'https://api.stage.trulet.com';
export const auth = 'https://api.stage.trulet.com/auth/';
export const users = 'https://api.stage.trulet.com/users/';
export const properties = 'https://api.stage.trulet.com/properties/';
export const conversations = 'https://api.stage.trulet.com/conversations/';
export const agencies = 'https://api.stage.trulet.com/agencies/';
export const voxeet = 'https://webapp.stage.trulet.com';
export const webSocketUrl = 'wss://message.stage.trulet.com';
export const env = 'stage';
export const mixpanelToken = '53e8a978480683df568ad82b33aca43e';
export const featureVersion = '1.00';
export const currentVersion = '1.00';
export const fileLimit = 25;
